import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { createManufacturersType } from '../../services/api';
import FormButtons from '../auth/FormButtons';

const TypeCreate = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();
    const goToIndex = () => navigate('/');
    const navigateToTypeList = () => navigate(`/type/${id}`);

    const { id } = useParams();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: '',
            cockpit_crew: '',
            passenger_capacity: '',
            max_seating: '',
            length_meters: '',
            wing_span: '',
            wing_surface: '',
            max_speed_kmh: '',
            manufacturer: id,
        },
    });

    const onSubmit = async (data) => {
        try {
            await createManufacturersType(access, data);

            navigateToTypeList();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="form-container">
            <form className="type-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Model"
                        />
                    )}
                />
                <Controller
                    name="cockpit_crew"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Broj posade"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="passenger_capacity"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Putnici"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="max_seating"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Kapacitet sjedenja"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="length_meters"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Duljina (m)"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="wing_span"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Raspon krila (m)"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="wing_surface"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Površina krila (m2)"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="max_speed_kmh"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Brzina (km/h)"
                            type="number"
                        />
                    )}
                />
                <FormButtons cancelFunc={goToIndex} />
            </form>
        </div>
    );
};

export default TypeCreate;
