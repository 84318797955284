import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    Box,
} from '@mui/material';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { deleteMaintenance, fetchMaintenance } from '../../services/api';

export const Maintenance = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();
    const navigateToIndex = () => navigate('/');
    const navigateToMaintenanceAdd = () => navigate(`/maintenance/add/${id}`);

    const { id } = useParams();
    const [data, setData] = useState([]);
    const [deleteState, setDeleteState] = useState(true);

    useEffect(() => {
        const getMaintenance = async () => {
            const data = await fetchMaintenance(access, id);

            setData(data);
        };

        getMaintenance();
    }, [access, id, deleteState]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleDelete = async (obj_id) => {
        try {
        const status = await deleteMaintenance(access, obj_id);
        if (status === 204) {
             if (deleteState) {
                setDeleteState(false);
            } else {
                setDeleteState(true);
            }
        } else {
            console.error('Item not found or unable to delete');
        }
    } catch (error) {
        console.error('An error occurred while deleting item:', error);
    }
      handleClose();
      navigateToIndex();
    };

    return Array.isArray(data) && data.length > 0 ? (
        <div className="table-container">
            <TableContainer className="manu-table" component={Paper}>
                <div className="pages-container">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIcon />}
                        sx={{ marginLeft: '1%', marginTop: '1%' }}
                        onClick={navigateToIndex}
                    >
                        NAZAD
                    </Button>
                    <Button variant="contained" color="primary"  sx={{ marginRight: '1%', marginTop: '1%' }} onClick={navigateToMaintenanceAdd}>
                        DODAJ
                    </Button>
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, padding: '10px' }} align="center">
                                Tip Aviona
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '10px' }} align="center">
                                Datum
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '10px' }} align="center">
                                Tip Održavanja
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '10px' }} align="center">
                                Dijelovi Popravljeni/Zamjenjeni
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '10px' }} align="center">
                                Pružatelj Održavanja
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '10px' }} align="center">
                                Cijena
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px', paddingRight: '60px'}} align="center">
                                Akcije
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(data) &&
                            data?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        {row.airplane_type}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        {row.maintenance_date}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        {row.type_of_service}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        {row.parts_repaired_replaced}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        {row.maintenance_provider}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        {row.cost}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '10px' }}>
                                        <Box sx={{ display: 'flex', gap: '8px' }}>
                                        <Button variant="contained" color="warning" size="small" sx={{ marginLeft: '7%' }} onClick={() => {}}>
                                                <EditIcon />
                                            </Button>
                                            <Button variant="contained" color="error" size="small" onClick={handleClickOpen}>
                                                <DeleteIcon />
                                            </Button>
                                            <Dialog open={open} onClose={handleClose}>
                                              <DialogTitle>Confirmation</DialogTitle>
                                              <DialogContent>
                                                <DialogContentText>
                                                  Are you sure you want to delete this item?
                                                </DialogContentText>
                                              </DialogContent>
                                              <DialogActions>
                                                <Button onClick={handleClose}>Cancel</Button>
                                                <Button onClick={() => handleDelete(row.id)} color="error">Delete</Button>
                                              </DialogActions>
                                            </Dialog>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <div className="table-container">
            <div className="info-container">
                <Typography>
                    <b>Nema informacija</b>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    sx={{ marginLeft: '10%' }}
                    onClick={navigateToIndex}
                >
                    NAZAD
                </Button>
                <Button variant="contained" color="primary" sx={{ marginLeft: '15%', marginTop: '5%' }} onClick={navigateToMaintenanceAdd}>
                    DODAJ
                </Button>
            </div>
        </div>
    );
};
