import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@mui/material';
import { createAirFactory } from '../../services/api';
import FormButtons from '../auth/FormButtons';

const AddFactory = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const goToIndex = () => navigate('/');
    const navigateToFactory = () => navigate(`/air_factory/${id}/`);
    const { id } = useParams();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: '',
            plane_type_model: '',
            location: '',
            manufacturer: id,
        },
    });

    const onSubmit = async (data) => {
        try {
            await createAirFactory(access, data);
            navigateToFactory();
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <div className="form-container">
            <form className="air-factory-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error?.message}
                            margin="normal"
                            label="Name"
                        />
                    )}
                />

                <Controller
                    name="plane_type_model"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error?.message}
                            margin="dense"
                            label="Aircraft model"
                        />
                    )}
                />

                <Controller
                    name="location"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error?.message}
                            margin="normal"
                            label="Location"
                        />
                    )}
                />
                <FormButtons cancelFunc={goToIndex} />
            </form>
        </div>
    );
};

export default AddFactory;
