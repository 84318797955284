import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';

import { deleteManufacturersType, fetchManufacturersType } from '../../services/api';

export const Type = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();
    const [deleteState, setDeleteState] = useState(true);

    const navigateToIndex = () => navigate('/');
    const navigateToTypeCreate = () => navigate(`/type/create/${id}`);

    const navigateToTypeUpdate = (data_id) => navigate(`/type/update/${data_id}`);

    const navigateToTypeList = () => navigate(`/type/${id}`);

    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        const getManufacturersType = async () => {
            const res = await fetchManufacturersType(access, id);

            setData(res);
        };

        getManufacturersType();
    }, [access, id, deleteState]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async (obj_id) => {
        try {
            const status = await deleteManufacturersType(access, obj_id);
            if (status === 204) {
                // Status 204 označava uspješno brisanje
                if (deleteState) {
                    setDeleteState(false);
                } else {
                    setDeleteState(true);
                }
            } else {
                console.error('Item not found or unable to delete');
            }
        } catch (error) {
            console.error('An error occurred while deleting item:', error);
        }
        handleClose();
        navigateToTypeList();
    };
    return Array.isArray(data) && data.length > 0 ? (
        <div className="table-container">
            <TableContainer className="manu-table" component={Paper}>
                <div className="pages-container">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIcon />}
                        sx={{ marginLeft: '1%', marginBottom: '1%' }}
                        onClick={navigateToIndex}
                    >
                        NAZAD
                    </Button>
                    <Button variant="contained" color="primary" onClick={navigateToTypeCreate}>
                        DODAJ
                    </Button>
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Ime</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Posada kokpita
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Putnici
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>
                                Max kapacitet sjedenja
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Duljina (m)
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Raspon krila (m)
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>
                                Površina krila (m2)
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Brzina (km/h)
                            </TableCell>
                            <TableCell
                                sx={{ fontWeight: 600, padding: '16px', paddingRight: '60px' }}
                                align="center"
                            >
                                Akcije
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(data) &&
                            data?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" sx={{ padding: '16px' }}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: '16px' }}>
                                        {row.cockpit_crew}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: '16px' }}>
                                        {row.passenger_capacity}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '16px' }}>
                                        {row.max_seating}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: '16px' }}>
                                        {row.length_meters}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: '16px' }}>
                                        {row.wing_span}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '16px' }}>
                                        {row.wing_surface}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: '16px' }}>
                                        {row.max_speed_kmh}
                                    </TableCell>
                                    <TableCell align="right" sx={{ padding: '16px' }}>
                                        <Box sx={{ display: 'flex', gap: '8px' }}>
                                            <Button
                                                variant="contained"
                                                color="warning"
                                                size="small"
                                                onClick={() => navigateToTypeUpdate(row.id)}
                                            >
                                                <EditIcon />
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="error"
                                                size="small"
                                                onClick={handleClickOpen}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                            <Dialog open={open} onClose={handleClose}>
                                                <DialogTitle>Confirmation</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        Are you sure you want to delete this item?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Cancel</Button>
                                                    <Button
                                                        onClick={() => handleDelete(row.id)}
                                                        color="error"
                                                    >
                                                        Delete
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <div className="table-container">
            <div className="info-container">
                <Typography>
                    <b>Nema informacija</b>
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    sx={{ marginLeft: '10%' }}
                    onClick={navigateToIndex}
                >
                    NAZAD
                </Button>
            </div>
        </div>
    );
};
