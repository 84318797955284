import { TextField } from '@mui/material';
import React, { useState } from 'react';

import { Controller, useForm } from 'react-hook-form';
import FormButtons from './FormButtons';
import { useNavigate } from 'react-router-dom';
import { fetchUser } from '../../services/api';

const LoginForm = () => {
    const navigate = useNavigate();
    const goToIndex = () => navigate('/');

    const [error, setError] = useState('');

    const { handleSubmit, control } = useForm({
        defaultValues: {
            username: '',
            password: '',
        },
    });

    const onSubmit = async (data) => {
        const user = await fetchUser(data);

        if (!user.ok) {
            setError('No account found with given credentials!');

            return;
        }

        const tokens = await user.json();

        localStorage.setItem('skolica8Token', tokens.access);

        goToIndex();
    };

    return (
        <div className="form-container">
            <form className="classic-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            error={error}
                            helperText={error}
                            fullWidth
                            margin="dense"
                            label="Korisničko ime"
                        />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            {...field}
                            type="password"
                            fullWidth
                            margin="dense"
                            label="Lozinka"
                        />
                    )}
                />
                <FormButtons cancelFunc={goToIndex} />
            </form>
        </div>
    );
};

export default LoginForm;
