import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { editManufacturersAward, fetchManufacturersAward } from '../../services/api';
import { TextField } from '@mui/material';
import FormButtons from '../auth/FormButtons';

const AwardUpdate = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const navigateToAwardList = (award_id) => navigate(`/award/${award_id}`);

    const { award_id } = useParams();

    const [formData, setFormData] = useState(null);

    useEffect(() => {
        const getManufacturersAward = async () => {
            const res = await fetchManufacturersAward(access, award_id);
            setFormData(res);
        };
        getManufacturersAward();
    }, [access, award_id]);

    const { handleSubmit, control } = useForm({
        defaultValues: null,
    });

    const onSubmit = async (data) => {
    try {
        const data_response = await editManufacturersAward(access, award_id, data);
        navigateToAwardList(data_response.manufacturer);
    } catch (error) {
        console.error(error);
    }
};

return (
    <div className="form-container">
        <form className="award-form" onSubmit={handleSubmit(onSubmit)}>
            {formData && (
                <>
             <Controller
                    name="name"
                    control={control}
                    defaultValue={formData.name}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Naziv"
                        />
                    )}
                />
                 <Controller
                    name="description"
                    control={control}
                    defaultValue={formData.description}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Opis"
                        />
                    )}
                />
                <Controller
                    name="passenger_capacity"
                    control={control}
                    defaultValue={formData.year_recived}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Godina dostignuća"
                            type="number"
                        />
                    )}
                />
</>
                )}
                <FormButtons cancelFunc={() => navigateToAwardList(formData.manufacturer)} />
            </form>
        </div>
    );
};

export default AwardUpdate;
