import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Button, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DeleteIcon from '@mui/icons-material/Delete'; 

import { fetchFlights, deleteFlights } from '../../services/api'; 

export const Flights = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const { id } = useParams();
    const [data, setData] = useState([]);

    useEffect(() => {
        const getFlights = async () => {
            const res = await fetchFlights(access, id);
            if (res) setData(res);
        };
        getFlights();
    }, [access, id]);

    const navigateToIndex = () => navigate('/');
    const navigateToFlightsCreate = () => navigate(`/flights/create/${id}`);
    const navigateToFlightsUpdate = (flightId) => navigate(`/flights/update/${flightId}`);

    const handleDeleteFlight = async (flightId) => {
        await deleteFlights(access, flightId); 
        setData(data.filter(flight => flight.id !== flightId)); 
    };

    return Array.isArray(data) && data.length > 0 ? (
        <div className="table-container">
            <TableContainer component={Paper}>
                <div>
                    <Button variant="contained" color="primary" startIcon={<ArrowBackIcon />} sx={{ marginLeft: '1%', marginBottom: '1%' }} onClick={navigateToIndex}>
                        NAZAD
                    </Button>
                    <Button variant="contained" color="secondary" sx={{ marginLeft: '1%', marginBottom: '1%' }} onClick={navigateToFlightsCreate}>
                        DODAJ
                    </Button>
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Route number</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Departure Airport</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Destination Airport</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Average Flight Time</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Distance</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Akcije</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <TableRow key={row.id}>
                                <TableCell align="left">{row.route_number}</TableCell>
                                <TableCell align="left">{row.departure_airport}</TableCell>
                                <TableCell align="left">{row.destination_airport}</TableCell>
                                <TableCell align="left">{row.average_flight_time}</TableCell>
                                <TableCell align="left">{row.distance}</TableCell>
                                <TableCell align="left">
                                    <Button variant="contained" color="secondary" onClick={() => navigateToFlightsUpdate(row.id)}>
                                        Ažuriraj
                                    </Button>
                                    {' '}
                                    <Button variant="contained" color="error" startIcon={<DeleteIcon />} onClick={() => handleDeleteFlight(row.id)}>
                                        Obriši
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <Box className="table-container">
            <Box className="info-container">
                <Typography fontWeight="bold">Nema Letova</Typography>
                <Button variant="contained" color="primary" startIcon={<ArrowBackIcon />} sx={{ marginLeft: '10%' }} onClick={navigateToIndex}>
                    NAZAD
                </Button>
            </Box>
        </Box>
    );
};
