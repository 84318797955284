import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { editFlights, fetchFlights } from '../../services/api'; 
import FormButtons from '../auth/FormButtons';

const FlightsUpdate = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();
    const goToIndex = () => navigate('/');
    const navigateToFlights = () => navigate(`/routes/${id}`);

    const { id } = useParams();

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            route_number: '',
            departure_airport: '',
            destination_airport: '',
            manufacturer: id,
            average_flight_time: '',
            distance: '',
        },
    });

    useEffect(() => {
        const fetchData = async () => {
            const res = await fetchFlights(access, id); 
            if (res) {
                reset({
                    route_number: res.route_number,
                    departure_airport: res.departure_airport,
                    destination_airport: res.destination_airport,
                    manufacturer: res.manufacturer,
                    average_flight_time: res.average_flight_time,
                    distance: res.distance,
                });
            }
        };
        fetchData();
    }, [reset, id, access]);

    const onSubmit = async (data) => {
        try {
            await editFlights(access, id, data); 

            navigateToFlights();
        } catch (error) {
            console.error(error);
        }
    };

    return (<div className="form-container">
            <form className="type-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="route_number"
                    control={control}
                    rules={{ required: 'Ovo polje je obavezno' }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            type="number"
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Broj leta"
                        />
                    )}
                />
                <Controller
                    name="departure_airport"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        minLength: {
                            value: 1,
                            message: 'Naziv aerodroma je obavezan',
                        },
                        maxLength: {
                            value: 200,
                            message: 'Naziv aerodroma je predugačak',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Polazni aerodrom"
                            type="text"
                        />
                    )}
                />

                <Controller
                    name="destination_airport"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        minLength: {
                            value: 1,
                            message: 'Naziv aerodroma je obavezan',
                        },
                        maxLength: {
                            value: 200,
                            message: 'Naziv aerodroma je predugačak',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Dolazni aerodrom"
                            type="text"
                        />
                    )}
                />
                <Controller
                    name="average_flight_time"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        pattern: {
                            value: /^(2[0-3]|[01]?[0-9]):([0-5]?[0-9]):([0-5]?[0-9])$/,
                            message: 'Vrijeme mora biti u formatu HH:MM:SS',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Prosječno vrijeme leta"
                            type="text"
                        />
                    )}
                />

                <Controller
                    name="distance"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        pattern: {
                            value: /^\d+(\.\d{1,2})?$/, 
                            message:
                                'Udaljenost mora biti decimalni broj (do dvije decimalne točke)',
                        },
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Udaljenost"
                            type="text" 
                        />
                    )}
                />

                <FormButtons cancelFunc={goToIndex} />
            </form>
        </div>
    );
};

export default FlightsUpdate;
