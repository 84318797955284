import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { editFactory, retrieveFactory } from '../../services/api';
import { TextField } from '@mui/material';
import FormButtons from '../auth/FormButtons';


const UpdateFactory = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const navigateToFactory = (data_id) => navigate(`/air_factory/${data_id}`);

    const { id } = useParams();

    const [formData, setFormData] = useState(null);

    useEffect(() => {
        const getAirFactory = async () => {
            const res = await retrieveFactory(access, id);
            setFormData(res);
        };
        getAirFactory();
    }, [access, id]);

    const { handleSubmit, control } = useForm({
        defaultValues: null,
    });

    const onSubmit = async (data) => {
        try {
            const data_response = await editFactory(access, id, data);
            navigateToFactory(data_response.manufacturer);
        } catch (error) {
            console.error(error);
        }
    };
    return (
        <div className="form-container">
            <form className="type-form" onSubmit={handleSubmit(onSubmit)}>
                {formData && (
                    <>
                        <Controller
                            name="name"
                            control={control}
                            defaultValue={formData.name}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={error !== undefined}
                                    helperText={error && error?.message}
                                    margin="dense"
                                    label="Ime"
                                />
                            )}
                        />
                        <Controller
                            name="plane_type_model"
                            control={control}
                            defaultValue={formData.plane_type_model}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={error !== undefined}
                                    helperText={error && error?.message}
                                    margin="dense"
                                    label="Model aviona" 
                                    />
                            )}
                        />
                        <Controller
                            name="location"
                            control={control}
                            defaultValue={formData.location}
                            render={({ field, fieldState: { error } }) => (
                                <TextField
                                    {...field}
                                    fullWidth
                                    error={error !== undefined}
                                    helperText={error && error?.message}
                                    margin="dense"
                                    label="Lokacija"                                   
                                />
                            )}
                        />
                        
                    </>
                )}
                <FormButtons cancelFunc={() => navigateToFactory(formData.manufacturer)} />
            </form>
        </div>
    );
};

export default UpdateFactory;
