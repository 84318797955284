import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { fetchAirFactory, deleteAirFactory } from '../../services/api';

const AirFactory = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();
    const navigateToIndex = () => navigate('/');

    const [deleteState, setDeleteState] = useState(true);

    const { id } = useParams();
    const navigateToAddFactory = () => navigate(`/add_factory/${id}/`);
    const navigateToAirFactory = () => navigate(`/air_factory/${id}/`);
    const navigateToUpdateFactory = (obj_id) => navigate(`/update_factory/${obj_id}/`);

    const [data, setData] = useState([]);

    useEffect(() => {
        const getAirFactory = async () => {
            const res = await fetchAirFactory(access, id);

            setData(res);
        };

        getAirFactory();
    }, [access, id, deleteState]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async (obj_id) => {
        try {
            const status = await deleteAirFactory(access, obj_id);
            if (status === 204) {
                // Status 204 označava uspješno brisanje
                if (deleteState) {
                    setDeleteState(false);
                } else {
                    setDeleteState(true);
                }
            } else {
                console.error('Item not found or unable to delete');
            }
        } catch (error) {
            console.error('An error occurred while deleting item:', error);
        }
        handleClose();
        navigateToAirFactory();
    };

    return Array.isArray(data) && data.length > 0 ? (
        <Box className="table-container">
            <TableContainer className="manu-table" component={Paper}>
                <Box className="pages-container">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIcon />}
                        sx={{ marginLeft: '1%', marginBottom: '1%' }}
                        onClick={navigateToIndex}
                    ></Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={navigateToAddFactory}
                        align="right"
                    >
                        DODAJ
                    </Button>
                </Box>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Ime</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Tip modela
                            </TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }} align="right">
                                Lokacija
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(data) &&
                            data?.map((row) => {
                                const { id, name, plane_type_model, location } = row;

                                return (
                                    <TableRow
                                        key={id}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        <TableCell component="th" scope="row">
                                            {name}
                                        </TableCell>
                                        <TableCell align="right">{plane_type_model}</TableCell>
                                        <TableCell align="right">{location}</TableCell>
                                        <TableCell align="right" sx={{ padding: '16px' }}>
                                            <Box sx={{ display: 'flex', gap: '8px' }}>
                                                <Button
                                                    variant="contained"
                                                    color="warning"
                                                    size="small"
                                                    onClick={() => navigateToUpdateFactory(row.id)}
                                                >
                                                    <EditIcon />
                                                </Button>
                                                <Button
                                                    variant="contained"
                                                    color="error"
                                                    size="small"
                                                    onClick={handleClickOpen}
                                                >
                                                    <DeleteIcon />
                                                </Button>
                                                <Dialog open={open} onClose={handleClose}>
                                                    <DialogTitle>Confirmation</DialogTitle>
                                                    <DialogContent>
                                                        <DialogContentText>
                                                            Are you sure you want to delete this
                                                            item?
                                                        </DialogContentText>
                                                    </DialogContent>
                                                    <DialogActions>
                                                        <Button onClick={handleClose}>
                                                            Cancel
                                                        </Button>
                                                        <Button
                                                            onClick={() => handleDelete(row.id)}
                                                            color="error"
                                                        >
                                                            Delete
                                                        </Button>
                                                    </DialogActions>
                                                </Dialog>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    ) : (
        <Typography>Nema informacija</Typography>
    );
};

export default AirFactory;
