import transformToFormData from '../utils/transformToFormData';

export const fetchUser = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/token/`, {
            method: 'POST',
            body: transformToFormData(data),
        });

        return response;
    } catch (err) {
        console.error(err);
    }
};

export const registerUser = async (data) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/auth/register/`, {
            method: 'POST',
            body: transformToFormData(data),
        });

        return response;
    } catch (err) {
        console.error(err);
    }
};

export const fetchManufacturers = async (token, page = 1, name = '') => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_man/?page=${page}&name=${name}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const fetchManufacturersType = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air-type/list/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const fetchFlights = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/flight_routes/${id}/routes/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const createFlights = async (token, requestData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/flight_routes/`, {
            method: 'POST',
            body: transformToFormData(requestData),
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const editFlights = async (token, obj_id, updatedData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/flight_routes/${obj_id}/`,
            {
                method: 'PUT',
                body: transformToFormData(updatedData),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const deleteFlights = async (token, obj_id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/flight_routes/${obj_id}/`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.status;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const fetchManufacturersAward = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_award/${id}/awards/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const createManufacturersAward = async (token, requestData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/air_award/`, {
            method: 'POST',
            body: transformToFormData(requestData),
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const deleteManufacturersAward = async (token, award_id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_award/${award_id}/`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.status;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const editManufacturersAward = async (token, award_id, updatedData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_award/${award_id}/`,
            {
                method: 'PUT',
                body: transformToFormData(updatedData),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const fetchAirFactory = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_factory/${id}/factories`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const createManufacturersType = async (token, requestData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/air-type/create/`, {
            method: 'POST',
            body: transformToFormData(requestData),
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const fetchMaintenance = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_maintenance/${id}/maintenances/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const createMaintenance = async (token, requestData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_maintenance/`,
            {
                method: 'POST',
                body: transformToFormData(requestData),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const deleteMaintenance = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_maintenance/${id}/`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.status;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const deleteManufacturersType = async (token, obj_id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air-type/detail/${obj_id}/`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.status;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const editManufacturersType = async (token, obj_id, updatedData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air-type/detail/${obj_id}/`,
            {
                method: 'PUT',
                body: transformToFormData(updatedData),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const retrieveManufacturersType = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air-type/detail/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const createAirFactory = async (token, requestData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}/api/v2/air_factory/`, {
            method: 'POST',
            body: transformToFormData(requestData),
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        return data;
    } catch (error) {
        console.error(error);
    }
};

export const deleteAirFactory = async (token, obj_id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_factory/${obj_id}/`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.status;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const fetchAirFinances = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_finances/${id}/finances`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = response.json();

        return data;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const deleteAirFinances = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_finances/${id}/`,
            {
                method: 'DELETE',
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        return response.status;
    } catch (err) {
        console.error(err);
        return '404';
    }
};

export const editFactory = async (token, obj_id, updatedData) => {
    try {
    const response = await fetch(
      `${process.env.REACT_APP_BASE_URL}/api/v2/air_factory/${obj_id}/`,
      {
        method: 'PUT',
        body: transformToFormData(updatedData),
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    const data = await response.json();

    return data
    } catch (err) {
        console.error(err);
    }
};


export const retrieveFactory = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/air_factory/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};


export const createAirFinances = async (token, requestData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_BASE_URL}api/v2/airplane_finances/`, {
            method: 'POST',
            body: transformToFormData(requestData),
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const retrieveAirFinances = async (token, id) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_finances/${id}/`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};

export const editAirFinances = async (token, id, updatedData) => {
    try {
        const response = await fetch(
            `${process.env.REACT_APP_BASE_URL}/api/v2/airplane_finances/${id}/`, 
            {
                method: 'PUT',
                body: JSON.stringify(updatedData), 
                headers: {
                    'Content-Type': 'application/json', 
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.json();

        return data;
    } catch (err) {
        console.error(err);
    }
};
