import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { TextField, Box, Button } from '@mui/material';
import { retrieveAirFinances, editAirFinances } from '../../services/api';
import FormButtons from '../auth/FormButtons';

const AirFinancesUpdate = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const { id } = useParams();
    const [formData, setFormData] = useState(null);

    useEffect(() => {
        const fetchAirFinances = async () => {
            const res = await retrieveAirFinances(access, id);
            setFormData(res);
            reset(res);
        };
        fetchAirFinances();
    }, [access, id]);

    const { handleSubmit, control, reset } = useForm({
        defaultValues: {
            revenue: '',
            expenses: '',
            net_profit: '',
            profit_margin: '',
            assets: '',
            liabilities: '',
        },
    });

    const onSubmit = async (data) => {
        try {
            await editAirFinances(access, id, data);
            navigate(`/airplane_finances/1`);
        } catch (error) {
            console.error('Failed to update air finance data:', error);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="revenue"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Revenue"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="expenses"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Expenses"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="net_profit"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Net Profit"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="profit_margin"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Profit Margin"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="assets"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Assets"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="liabilities"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Liabilities"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}>
                    <Button type="submit" variant="contained" color="primary">Update</Button>
                </Box>
            </form>
        </Box>
    );
};

export default AirFinancesUpdate;
