import { BrowserRouter, Route, Routes } from 'react-router-dom';

import HomePage from './components/HomePage';
import NavigationBar from './components/NavigationBar';
import LoginForm from './components/auth/LoginForm';
import { Type } from './components/Index/Type';
import { Award } from './components/Index/Award';
import AwardCreate from './components/Index/AwardCreate';
import AwardUpdate from './components/Index/AwardUpdate';
import AirFactory from './components/Index/AirFactory';
import { Flights } from './components/Index/Flights';

import TypeCreate from './components/Index/TypeCreate';
import SignupForm from './components/auth/SignupForm';
import { Maintenance } from './components/Index/Maintenance';
import AddFactory from './components/Index/AddFactory';

import FlightsCreate from './components/Index/FlightsCreate';
import FlightsUpdate from './components/Index/FlightsUpdate';
import AirFinances from './components/Index/AirFinances';
import AirFinancesCreate from './components/Index/AirFinancesCreate'; 
import AirFinancesUpdate from './components/Index/AirFinancesUpdate';
import TypeUpdate from './components/Index/TypeUpdate';
import MaintenanceCreate from './components/Index/MaintenanceCreate';
import UpdateFactory from './components/Index/UpdateFactory';
import AppFooter from './components/Footer';

// SCSS Imports
import './styles/global.scss';
import './styles/navigation.scss';
import './styles/auth.scss';
import './styles/table.scss';

function App() {
    return (
        <BrowserRouter>
            <NavigationBar />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginForm />} />
                <Route path="/signup" element={<SignupForm />} />
                <Route path="/type/:id" element={<Type />} />
                <Route path="/award/:id" element={<Award />} />
                <Route path="/award/create/:id" element={<AwardCreate />} />
                <Route path="/award/update/:id" element={<AwardUpdate />} />
                <Route path="/air_factory/:id" element={<AirFactory />} />
                <Route path="/type/create/:id" element={<TypeCreate />} />
                <Route path="/maintenance/:id" element={<Maintenance />} />
                <Route path="type/update/:id" element={<TypeUpdate />} />s
                <Route path="add_factory/:id" element={<AddFactory />} />
                <Route path="routes/:id" element={<Flights />} />
                <Route path="/airplane_finances/:id" element={<AirFinances />} />
                <Route path="/flights/create/:id" element={<FlightsCreate />} />
                <Route path="flights/update/:id" element={<FlightsUpdate />} />
                <Route path="/air_finances/:id" element={<AirFinances />} />
                <Route path="/airplane_finances/create/:id" element={<AirFinancesCreate />} />
                <Route path="/airplane_finances/update/:id" element={<AirFinancesUpdate />} />
                <Route path="/maintenance/add/:id" element={<MaintenanceCreate />} />
                <Route path="/update_factory/:id" element={<UpdateFactory />} />
            </Routes>
            <AppFooter />
        </BrowserRouter>
    );
}

export default App;
