import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    Button,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Edit as EditIcon, Delete as DeleteIcon, AddBox as AddBoxIcon } from '@mui/icons-material';
import { deleteAirFinances, fetchAirFinances } from '../../services/api';

const AirFinances = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const navigateToIndex = () => navigate('/');
    const navigateToAirFinancesCreate = () => navigate(`/airplane_finances/create/${id}`);
    const navigateToAirFinancesUpdate = (data_id) => navigate(`/airplane_finances/update/${data_id}`);
    const { id } = useParams();
    const [data, setData] = useState([]);
    const [deleteState, setDeleteState] = useState(true);

    useEffect(() => {
        const getAirFinances = async () => {
            const res = await fetchAirFinances(access, id);
            setData(res);
        };
        getAirFinances();
    }, [access, id, deleteState]);

    const handleDelete = async (id) => {
        try {
            const status = await deleteAirFinances(access, id);
            if (status === 204) {
                setDeleteState(!deleteState);
            } else {
                console.error('Item not found or unable to delete');
            }
        } catch (error) {
            console.error('An error occurred while deleting item:', error);
        }
        navigateToIndex();
    };

    return (
        <div
            className="table-container"
            style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}
        >
            <Button
                variant="contained"
                color="primary"
                startIcon={<ArrowBackIcon />}
                onClick={navigateToIndex}
                style={{ marginBottom: '20px' }}
            >
                NATRAG
            </Button>
            <Button
                variant="contained"
                color="secondary"
                startIcon={<AddBoxIcon />}
                onClick={navigateToAirFinancesCreate}
                style={{ marginBottom: '20px' }}
            >
                Add New
            </Button>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Revenue</TableCell>
                            <TableCell align="center">Expenses</TableCell>
                            <TableCell align="center">Net Profit</TableCell>
                            <TableCell align="center">Profit Margins</TableCell>
                            <TableCell align="center">Assets</TableCell>
                            <TableCell align="center">Liabilities</TableCell>
                            <TableCell align="center">Created At</TableCell>
                            <TableCell align="center">Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(data) &&
                            data.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="center">{row.revenue}</TableCell>
                                    <TableCell align="center">{row.expenses}</TableCell>
                                    <TableCell align="center">{row.net_profit}</TableCell>
                                    <TableCell align="center">{row.profit_margin}</TableCell>
                                    <TableCell align="center">{row.assets}</TableCell>
                                    <TableCell align="center">{row.liabilities}</TableCell>
                                    <TableCell align="center">{row.created_at}</TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            color="primary"
                                            onClick={() => navigateToAirFinancesUpdate(row.id)}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            color="error"
                                            onClick={() => handleDelete(row.id)}
                                        >
                                            <DeleteIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

export default AirFinances;
