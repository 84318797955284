import React from 'react';

import { Box, Button } from '@mui/material';

const FormButtons = ({ cancelFunc, cancelText = 'Odustani', submitText = 'Spremi' }) => {
    return (
        <Box className="form-btns">
            <Button variant="outlined" className="cancel-btn" onClick={cancelFunc}>
                {cancelText}
            </Button>
            <Button variant="contained" className="submit-btn" type="submit">
                {submitText}
            </Button>
        </Box>
    );
};

export default FormButtons;
