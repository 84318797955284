import React from 'react';
import { useNavigate } from 'react-router-dom';

import { TextField } from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import FormButtons from './FormButtons';
import { registerUser } from '../../services/api';

const SignupForm = () => {
    const navigate = useNavigate();
    const goToHome = () => navigate('/');

    const { handleSubmit, control, watch } = useForm({
        defaultValues: {
            username: '',
            email: '',
            password: '',
            confirm_password: '',
        },
    });

    const onSubmit = async (data) => {
        const res = await registerUser(data);

        if (res.ok) {
            const data = await res.json();

            localStorage.setItem('skolica8Token', data.access);

            goToHome();
        }
    };

    return (
        <div className="form-container">
            <form className="classic-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                        <TextField {...field} fullWidth margin="dense" label="Korisničko ime" />
                    )}
                />
                <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                        <TextField {...field} fullWidth margin="dense" label="Email" />
                    )}
                />
                <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                        <TextField
                            type="password"
                            {...field}
                            fullWidth
                            margin="dense"
                            label="Lozinka"
                        />
                    )}
                />
                <Controller
                    rules={{
                        validate: (value) => value === watch('password'),
                    }}
                    name="confirm_password"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            type="password"
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            margin="dense"
                            label="Potvrdi lozinku"
                            helperText={error && 'Lozinka se ne poklapa'}
                        />
                    )}
                />
                <FormButtons cancelFunc={goToHome} />
            </form>
        </div>
    );
};

export default SignupForm;
