import React, { useState, useEffect } from 'react';

const AppFooter = () => {
  const [currentDate, setCurrentDate] = useState(new Date().toLocaleString());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentDate(new Date().toLocaleString());
    }, 1000); // osvježavanje svake sekunde

    return () => clearInterval(intervalId);
  }, []); // koristimo prazno polje ovisnosti kako bismo osigurali da se useEffect izvrši samo jednom

  return (
    <footer className="footer">
      &copy; {new Date().getFullYear()} Školica8 | {currentDate}
    </footer>
  );
};

export default AppFooter;