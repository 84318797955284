import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    Button,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { fetchManufacturersAward, deleteManufacturersAward } from '../../services/api';

export const Award = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();

    const navigateToIndex = () => navigate('/');

    const [deleteState, setDeleteState] = useState(true);

    const navigateToAwardCreate = () => navigate(`/Award/create/${id}`);
    const navigateToAwardUpdate = (data_id) => navigate(`/award/update/${id}`);
    

    const { id } = useParams();

    const [data, setData] = useState([]);

    useEffect(() => {
        const getManufacturersAward = async () => {
            const res = await fetchManufacturersAward(access, id);

            if (res) setData(res);
        };

        getManufacturersAward();
    }, [access, id, deleteState]);

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = async (award_id) => {
        try {
            const status = await deleteManufacturersAward(access, award_id);
            if (status === 204) {
                if (deleteState) {
                    setDeleteState(false);
                } else {
                    setDeleteState(true);
                }
            } else {
                console.error('Brisanje nije moguće');
            }
        } catch (error) {
            console.error('Greška tokom brisanja', error);
        }
        handleClose();
        navigateToIndex();
    };

    return Array.isArray(data) && data.length > 0 ? (
        <div className="table-container">
            <TableContainer className="manu-table" component={Paper}>
                <div className="pages-container">
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<ArrowBackIcon />}
                        sx={{ marginLeft: '1%', marginBottom: '1%' }}
                        onClick={navigateToIndex}
                    >
                        NAZAD
                    </Button>
                    <Button variant="contained" color="primary" onClick={navigateToAwardCreate}>
                        DODAJ
                    </Button>
                </div>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Ime</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>Opis</TableCell>
                            <TableCell sx={{ fontWeight: 600, padding: '16px' }}>
                                Godina dostignuća
                            </TableCell>
                            <TableCell
                                sx={{ fontWeight: 600, padding: '16px', paddingRight: '60px' }}
                                align="left"
                            >
                                Akcije
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {Array.isArray(data) &&
                            data?.map((row) => (
                                <TableRow
                                    key={row.id}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                >
                                    <TableCell align="left" sx={{ padding: '16px' }}>
                                        {row.name}
                                    </TableCell>
                                    <TableCell align="left" sx={{ padding: '16px' }}>
                                        {row.description}
                                    </TableCell>
                                    <TableCell align="left" sx={{ padding: '16px' }}>
                                        {row.year_received}
                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: '16px' }}>
                                        <Box sx={{ display: 'flex', gap: '8px' }}>
                                        <Button variant="contained" color="warning" size="small" onClick={() => navigateToAwardUpdate(id)}>
                                                <EditIcon />
                                            </Button>
                                            
                                            <Button variant="contained" color="error" size="small" align="center" onClick={handleClickOpen}>
                                                <DeleteIcon />
                                            </Button>
                                            <Dialog open={open} onClose={handleClose}>
                                                <DialogTitle>Potvrda Brisanja</DialogTitle>
                                                <DialogContent>
                                                    <DialogContentText>
                                                        Da li ste sigurni?
                                                    </DialogContentText>
                                                </DialogContent>
                                                <DialogActions>
                                                    <Button onClick={handleClose}>Prekid</Button>
                                                    <Button onClick={() => handleDelete(row.id)} color="error">
                                                        Obriši
                                                    </Button>
                                                </DialogActions>
                                            </Dialog>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    ) : (
        <Box className="table-container">
            <Box className="info-container">
                <Typography fontWeight="bold">Nema nagrada</Typography>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<ArrowBackIcon />}
                    sx={{ marginLeft: '10%' }}
                    onClick={navigateToIndex}
                >
                    NAZAD
                </Button>
            </Box>
        </Box>
    );
};
