import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { createAirFinances } from '../../services/api';

const AirFinancesCreate = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const { handleSubmit, control } = useForm({
        defaultValues: {
            revenue: '',
            expenses: '',
            net_profit: '',
            profit_margin: '',
            assets: '',
            liabilities: '',
        },
    });

    const onSubmit = async (data) => {
        try {
            await createAirFinances(access, data);
            navigate(`/airplane_finances/1`);
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', margin: 4 }}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="revenue"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        min: {
                            value: 0,
                            message: 'Value cannot be less than 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Revenue"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="expenses"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        min: {
                            value: 0,
                            message: 'Value cannot be less than 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Expenses"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="net_profit"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Net Profit"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="profit_margin"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Profit Margin"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="assets"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Assets"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Controller
                    name="liabilities"
                    control={control}
                    rules={{
                        required: 'This field is required',
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            label="Liabilities"
                            type="number"
                            error={!!error}
                            helperText={error ? error.message : ''}
                            fullWidth
                            margin="normal"
                        />
                    )}
                />
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', margin: 2 }}>
                    <Button type="submit" variant="contained" color="primary">
                        Submit
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default AirFinancesCreate;
