import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { createManufacturersAward } from '../../services/api';
import FormButtons from '../auth/FormButtons';

const AwardCreate = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();

    const goToAwards = () => navigate(`/award/${id}`);

    const { id } = useParams();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            name: '',
            description: '',
            year_received: '',
            manufacturer: id,
        },
    });

    const onSubmit = async (data) => {
        try {
            await createManufacturersAward(access, data);

            goToAwards();
        } catch (error) {
            console.error(error);
        }
    };

    
    return (
        <div className="form-container">
            <form className="award-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="name"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Naziv"
                        />
                    )}
                />
                <Controller
                    name="description"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Opis"
                        />
                    )}
                />

                <Controller
                    name="year_received"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Godina dostignuća"
                            type="number"
                        />
                    )}
                />

                <FormButtons cancelFunc={goToAwards} />
            </form>
        </div>
    );
};

export default AwardCreate;
