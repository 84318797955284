import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { editManufacturersType, retrieveManufacturersType } from '../../services/api';
import { TextField } from '@mui/material';
import FormButtons from '../auth/FormButtons';

const TypeUpdate = () => {
    const access = localStorage.getItem('skolica8Token');
    const navigate = useNavigate();
    const navigateToTypeList = (data_id) => navigate(`/type/${data_id}`);

    const { id } = useParams();

    const [formData, setFormData] = useState(null);

    useEffect(() => {
        const getManufacturersType = async () => {
            const res = await retrieveManufacturersType(access, id);
            setFormData(res);
        };
        getManufacturersType();
    }, [access, id]);

    const { handleSubmit, control } = useForm({
        defaultValues: null,
    });

    const onSubmit = async (data) => {
    try {
        const data_response = await editManufacturersType(access, id, data);
        navigateToTypeList(data_response.manufacturer);
    } catch (error) {
        console.error(error);
    }
};
  return (
        <div className="form-container">
            <form className="type-form" onSubmit={handleSubmit(onSubmit)}>
                {formData && (
                    <>
                <Controller
                    name="name"
                    control={control}
                    defaultValue={formData.name}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Model"
                        />
                    )}
                />
                <Controller
                    name="cockpit_crew"
                    control={control}
                    defaultValue={formData.cockpit_crew}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Broj posade"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="passenger_capacity"
                    control={control}
                    defaultValue={formData.passenger_capacity}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Putnici"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="max_seating"
                    control={control}
                    defaultValue={formData.max_seating}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Kapacitet sjedenja"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="length_meters"
                    control={control}
                    defaultValue={formData.length_meters}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Duljina (m)"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="wing_span"
                    control={control}
                    defaultValue={formData.wing_span}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Raspon krila (m)"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="wing_surface"
                    control={control}
                    defaultValue={formData.wing_surface}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Površina krila (m2)"
                            type="number"
                        />
                    )}
                />
                <Controller
                    name="max_speed_kmh"
                    control={control}
                    defaultValue={formData.max_speed_kmh}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Brzina (km/h)"
                            type="number"
                        />
                    )}
                />
                </>
                )}
                <FormButtons cancelFunc={() => navigateToTypeList(formData.manufacturer)} />
            </form>
        </div>
    );
};

export default TypeUpdate;