import Button from '@mui/material/Button';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import FactoryIcon from '@mui/icons-material/Factory';
import EngineeringIcon from '@mui/icons-material/Engineering';
import PublicIcon from '@mui/icons-material/Public';
import { useNavigate } from 'react-router-dom';
import {
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import React from 'react';

const IndexTable = ({ data, handlePagination, setName }) => {
    const navigate = useNavigate();

    const navigateToType = (typeId) => navigate(`/type/${typeId}`);
    const navigateToAward = (awardId) => navigate(`/award/${awardId}`);
    const navigateToFactory = (factoryId) => navigate(`/air_factory/${factoryId}`);
    const navigateToMaintenance = (maintenanceId) => navigate(`/maintenance/${maintenanceId}`);
    const navigateToFlights = (flightId) => navigate(`/routes/${flightId}`);
    const navigateToFinances = (financesId) => navigate(`/airplane_finances/${financesId}`);

    const count = Math.ceil(data.count / data.page_size);

    const handleChange = (event) => {
        setName(event.target.value);
    };

    return (
        <div className="table-container">
            <TextField
                onChange={handleChange}
                size="small"
                label="Naziv"
                sx={{ alignSelf: 'flex-end', mb: 2 }}
            />
            {Array.isArray(data.results) && data.results.length > 0 ? (
                <>
                    <TableContainer className="manu-table" component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{ fontWeight: 600 }}>Puno ime</TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="right">
                                        Ime
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="right">
                                        Godina osnivanja
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="center">
                                        Tipovi
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="center">
                                        Nagrade
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="center">
                                        Tvornice
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="center">
                                        Održavanje
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="center">
                                        Letovi
                                    </TableCell>
                                    <TableCell sx={{ fontWeight: 600 }} align="center">
                                        Financije
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {Array.isArray(data.results) &&
                                    data?.results?.map((row) => (
                                        <TableRow
                                            key={row.id}
                                            sx={{
                                                '&:last-child td, &:last-child th': { border: 0 },
                                            }}
                                        >
                                            <TableCell component="th" scope="row">
                                                {row.full_name}
                                            </TableCell>
                                            <TableCell align="right">{row.name}</TableCell>
                                            <TableCell align="right">
                                                {row.year_established}
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AirplanemodeActiveIcon />}
                                                    onClick={() => navigateToType(row.id)}
                                                >
                                                    {row.airplane_types}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<EmojiEventsIcon />}
                                                    onClick={() => navigateToAward(row.id)}
                                                >
                                                    {row.awards}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<FactoryIcon />}
                                                    onClick={() => navigateToFactory(row.id)}
                                                >
                                                    {row.factory}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<EngineeringIcon />}
                                                    onClick={() => navigateToMaintenance(row.id)}
                                                >
                                                    {row.maintenances}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<PublicIcon />}
                                                    onClick={() => navigateToFlights(row.id)}
                                                >
                                                    {row.flights}
                                                </Button>
                                            </TableCell>
                                            <TableCell align="center">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<PublicIcon />}
                                                    onClick={() => navigateToFinances(row.id)}
                                                >
                                                    {row.finances}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Pagination
                        onChange={handlePagination}
                        count={count}
                        shape="rounded"
                        color="primary"
                        sx={{ mt: 2 }}
                    />
                </>
            ) : (
                <Typography>Nema informacija</Typography>
            )}
        </div>
    );
};

export default IndexTable;
