import { React, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';

import { createMaintenance } from '../../services/api';
import FormButtons from '../auth/FormButtons';

const types = [
    {
        value: 1,
        label: 'Tip #1',
    },
    {
        value: 2,
        label: 'Tip #2',
    },
];

const MaintenanceCreate = () => {
    const access = localStorage.getItem('skolica8Token');

    const navigate = useNavigate();
    const goToIndex = () => navigate('/');
    const navigateToMaintenance = () => navigate(`/maintenance/${id}`);

    const { id } = useParams();

    const { handleSubmit, control } = useForm({
        defaultValues: {
            maintenance_date: '',
            type_of_service: '',
            parts_repaired_replaced: '',
            maintenance_provider: '',
            cost: '',
            airplane_type: '',
            manufacturer_id: id,
        },
    });

    const onSubmit = async (data) => {
        try {
            await createMaintenance(access, data);

            navigateToMaintenance();
        } catch (err) {
            console.error(err);
        }
    };

    return (
        <div className="form-container">
            <form className="type-form" onSubmit={handleSubmit(onSubmit)}>
                <Controller
                    name="airplane_type"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Tip Aviona"
                        />
                    )}
                />

                <Controller
                    name="maintenance_date"
                    control={control}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Datum"
                        />
                    )}
                />

                <Controller
                    name="type_of_service"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            select
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Tip održavanja"
                        >
                            {types.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                    {option.label}
                                </MenuItem>
                            ))}
                        </TextField>
                    )}
                />
                <Controller
                    name="parts_repaired_replaced"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Dijelovi popravljeni/izmijenjeni"
                        />
                    )}
                />
                <Controller
                    name="maintenance_provider"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Pružatelj održavanja"
                        />
                    )}
                />
                <Controller
                    name="cost"
                    control={control}
                    rules={{
                        required: 'Ovo polje je obavezno',
                        min: {
                            value: 0,
                            message: 'Broj ne može biti manji od 0',
                        },
                        valueAsNumber: true,
                    }}
                    render={({ field, fieldState: { error } }) => (
                        <TextField
                            {...field}
                            fullWidth
                            error={error !== undefined}
                            helperText={error && error?.message}
                            margin="dense"
                            label="Cijena"
                            type="number"
                        />
                    )}
                />

                <FormButtons cancelFunc={goToIndex} />
            </form>
        </div>
    );
};

export default MaintenanceCreate;
