import React, { useEffect, useState } from 'react';

import IndexTable from './Index/IndexTable';
import { fetchManufacturers } from '../services/api';

const HomePage = () => {
    const access = localStorage.getItem('skolica8Token');

    const [data, setData] = useState([]);
    const [page, setPage] = useState();
    const [name, setName] = useState('')

    const handlePagination = (event, value) => {
        setPage(value);
    };

    useEffect(() => {
        const getManufacturers = async () => {
            const res = await fetchManufacturers(access, page, name);

            setData(res);
        };

        getManufacturers();
    }, [access, page, name]);

    return <IndexTable data={data} setName={setName} handlePagination={handlePagination} />
};

export default HomePage;
