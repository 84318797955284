import React from 'react';
import { useNavigate } from 'react-router-dom';

import { AppBar, Box, Button, Container, Toolbar, Typography } from '@mui/material';

const NavigationBar = () => {
    const navigate = useNavigate();

    const token = localStorage.getItem('skolica8Token');

    // Utility functions
    const navigateToIndex = () => navigate('/');
    const navigateToLogin = () => navigate('/login');
    const navigateToSignup = () => navigate('/signup');

    const logoutUser = () => localStorage.removeItem('skolica8Token');

    const pages = [
        {
            id: 1,
            label: 'Login',
            onClick: navigateToLogin,
            hide: token,
        },
        {
            id: 2,
            label: 'Sign up',
            onClick: navigateToSignup,
            hide: token,
        },
        {
            id: 3,
            label: 'Sign out',
            onClick: logoutUser,
            hide: !token,
        },
    ];

    return (
        <AppBar position="static">
            <Container maxWidth="xl">
                <Toolbar disableGutters className="pages-container">
                    <img src="logo.png" alt="logo" />
                    <Typography
                        variant="h5"
                        noWrap
                        component="a"
                        href="#app-bar-with-responsive-menu"
                        sx={{
                            mr: 2,
                            display: { xs: 'flex', md: 'none' },
                            flexGrow: 1,
                            fontFamily: 'monospace',
                            fontWeight: 700,
                            letterSpacing: '.3rem',
                            color: 'inherit',
                            textDecoration: 'none',
                        }}
                    >
                        LOGO
                    </Typography>
                    <Box>
                        {pages.map((item) => {
                            if (item.hide) return null;

                            return (
                                <Button
                                    onClick={item.onClick}
                                    sx={{ color: 'white' }}
                                    className="page-btn"
                                    key={item.id}
                                >
                                    {item.label}
                                </Button>
                            );
                        })}
                    </Box>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default NavigationBar;
